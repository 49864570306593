import { Grid } from '@/libs/chart-lib/classes/Grid';
import { Chart, Ticks } from '@/libs/chart-lib/index';
import { ChTimeItem } from '@/utilities';

const canvasPaddings = [10, 50, 40, 50];

export const getRangeByYearUTC = (year: number) => {
  const from = new Date(Date.UTC(year, 0, 0, 0));
  const to = new Date(Date.UTC(year, 12, 1, 23));
  return [from.getTime(), to.getTime()];
};

interface ChartData {
  container: HTMLElement;
  dataList: { date: ChTimeItem; color?: string; value: number }[];
  rangeYear: number;
  avgActivity?: number;
}

export function createChart({ container, dataList, rangeYear, avgActivity }: ChartData) {
  const mitreScoreValues = dataList.map(({ value }) => value);
  const dates = dataList.map(({ date }) => date.displayDate);

  const chart: Chart = new Chart(container, getRangeByYearUTC(rangeYear), [0, 1]);

  const { xAxis, yAxis } = chart;

  // X AXIS
  xAxis.display = false;
  xAxis.ticks.label.setOptions(true, '#0000004D', 'bottom', 1.5 * 9, ['12', 'JetBrains Mono']);
  xAxis.ticks.setOptions(true, 'timestampMonthStart');
  xAxis.ticks.settickDrawOptions(-6, 1, '#000000');
  xAxis.grid.setOptions(true, '#00000026', 1, [1, 0]);

  const midTimestampTicks = new Ticks(chart.xAxis.type);
  midTimestampTicks.setOptions(false, 'timestampMonthBetween', 5);
  midTimestampTicks.label.setOptions(false);
  const midGrid = new Grid(chart.xAxis.type);
  midGrid.setOptions(true, '#0000000D', 1, [1, 0]);
  midTimestampTicks.setOwnGrid(midGrid);
  chart.xAxis.addCustomTicks(midTimestampTicks);

  // bind ticks change
  midTimestampTicks.onCoordsChanged.add(() => {
    if (midTimestampTicks.coords.length >= 2) {
      const dx_tick = Math.round(
        Math.abs(midTimestampTicks.coords[1].x - midTimestampTicks.coords[0].x)
      );
      const size = 12 + 3 * 2; // width + 2x radius
      const barPlot = chart.findPlotById('bars');
      if (barPlot) {
        barPlot._options.mainSize = (dx_tick <= size ? dx_tick : size) - 3 * 2 - 1;
        chart.seriesReDraw(barSeries);
      }
    }
  });

  // Y AXIS
  yAxis.display = false;
  yAxis.ticks.label.setOptions(false);

  // bars
  chart
    .addPlot('bars', 'bars_rounded', '#000000', 12, 3, {
      x: 0,
      y: 0,
    })
    .addTooltip('date-label', 'label_x_start', 0, 'transparent', 'transparent', 0, dates)
    .label.setOptions(true, 'black', 'bottom', 0, ['12', 'JetBrains Mono'])
    .setCenterX(-0.5)
    .setBackgroundRectangle({
      padding: [12, 6, 12, 6],
      backgroundColor: '#ffffff',
      radius: 8,
      offset: { x: 0, y: -1 },
      border: {
        size: 0.5,
        color: '#000000',
      },
    })
    .setOffset(0, -16);

  const barSeries = chart
    .addSeries(
      'mitre-score',
      [dataList.map(({ date }) => date.timestamp), mitreScoreValues],
      dataList.map(() => 'none'),
      dataList.map(({ color }) => ({ color }))
    )
    .setPlotsIds('bars');

  // avg line
  if (avgActivity) {
    chart.addPlot('avg-line', 'line_horizontal', 1, '#00000066', [3, 2], 'round');

    chart
      .addSeries('avg-series', [[dataList.map(({ date }) => date.timestamp)[0]], [avgActivity]])
      .setPlotsIds('avg-line');
  }

  // set ranges
  const yMinMax = chart.data.findExtremes('ind', chart.xAxis.min, chart.xAxis.max);
  chart.yAxis.setMinMaxStatic([0, yMinMax[1] * 1.2]);

  chart.setCanvasPaddings(
    canvasPaddings[0],
    canvasPaddings[1],
    canvasPaddings[2],
    canvasPaddings[3]
  );
  chart.refresh();
  return chart;
}
